@font-face{
  font-family: "Tahoma";
    src: url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.eot");
    src: url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.svg#Tahoma")format("svg");

    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
html, body{
  overflow: hidden;
  font-family: "Tahoma";
}

.time-selector{
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 20px;
  margin-top: 20px;
  /* margin: auto; */
  /* background-color: ; */
}
.App {
  text-align: center; 
  min-height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
