.footer {
    /* background-color: #118976; */
    color: black;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;  
    /* margin: 0em; */
    margin-bottom: 10px;
    /* position: relative; */
    /* position: "absolute"; */
    position: absolute;
    /* margin-top: 20px; */
}